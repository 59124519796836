<template>
  <v-skeleton-loader
    :loading="loadingTable"
    :transition="'fade-transition'"
    height="500"
    type="table"
  >
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Movimentação
            </th>
            <th class="text-right">
              Valor
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="data in dataTable"
            :key="data.date"
          >
            <td>{{ data.dateFormat }} {{ data.diaSemana }}</td>
            <td class="text-right">{{ data.value | currency }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-skeleton-loader>
</template>

<script>
import variables from '@/assets/styles/helpers/_variables.scss'
export default {
  name: 'TabelaRelatorioRevenda',
  props: {
    totais: {
      type: Object,
      default: () => {},
      required: true
    },
    dataTable: {
      type: Array,
      default: () => [],
      required: true
    },
  },
  data: () => ({
    searchLocal: ''
  }),

  mounted () {
    this.searchLocal = this.search
  },

   watch: {
     'search' (val) {
       this.searchLocal = val
     }
   },

  computed: {
    headersData () {
      return [
        { align: 'start', width: 50, class: 'table-header', cellClass: 'text-truncate', text: this.getTextHeaderTypeGecom(this.typeGecom), value: 'cod' },
        { filter: false, align: 'end', class: 'table-header', text: 'Anterior', value: 'deve_ant' },
        { filter: false, align: 'end', class: 'table-header', text: 'Movimento', value: 'movimento' },
        { filter: false, align: 'end', class: 'table-header', text: 'Atual', value: 'deve_atual' },
        { filter: false, align: 'end', class: 'table-header', text: '', value: '' },
      ]
    },

    variables: () => variables
  },

  methods: {
    formatName (item) {
      return item.json_data ? `(${item.json_data.cod}) ${item.json_data.name}` : item.name
    },

    getHeaders (typesNotHeader) {
      return this.headersData.filter(item => {
        return !typesNotHeader.includes(item.value)
      })
    },

    openProposta(val) {
      this.$emit('openProposta', val)
    },

    opemModalEntidade(val) {
      this.$emit('openModalEntidade', val)
    },

    getTextHeaderTypeGecom (typeGecom) {
      switch (typeGecom) {
        case null:
          return 'Supervisor'
        case 2:
          return 'Vendedor'
        case 3:
        case 4:
          return 'RV'
        default:
          return 'RV'
      }
    },

    getColor (item) {
      const vendas = parseInt(item.deve_ant)
      const vendasAnterior = parseInt(item.deve_atual)
      if (!vendas) return this.variables.colorError

      const percentage = (vendas / vendasAnterior) * 100
      const { success, warning } = this.configGecom.percentageSales
      if (percentage >= success) {
        return this.variables.colorSecondary
      }

      if (percentage >= warning) {
        return this.variables.colorWarning
      }

      return this.variables.colorError
    },

    isDeleted (data) {
      if (data.entity_deleted > '1970-01-01'){
        return true
      }

      return false
    },

    customFilter (value, search, item) {
      // debugger
      console.log(value, search, item)
      return item != null &&
        search != null &&
        item.entity_cod.toString().indexOf(search) !== -1
    },
  }
}
</script>

<style lang="scss" scoped>
  .text-name {
    display: inline-block;
    line-height: 0.9;

    @media (min-width: 420px) {
      width: 115px!important;
    } 
  }

  .cadastro-usuario >>> .v-data-table__wrapper table {
    min-width: auto;
  }

  .box-table::v-deep {

    table {
      min-width: 400px;
    }

    .cancelado {
      color: #F00;
    }

    .mobile {
      @media (max-width: 420px) {
        display: none;
      } 
    }

    .v-btn {
      height: 30px !important;

    }
  }
  
</style>